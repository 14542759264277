<template>
    <div>
        <div class="header px-5 py-3">
            <div class="d-flex">
                <h1 class="fw-normal mb-0 fs-30" style="color:#08aee9;">Digicollect</h1>
                <img style="margin-left:10px" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/Digicollect%20Logo@3x.png" width="40" height="35"
                    alt="">
            </div>
            <span class="fs-14 fw-normal">Intelligent Industrial Internet</span>
        </div>
        <div class="px-5 mt-5">
            <h1 class="text-center fs-30 fw-normal my-4" style="color:#206ad3">Survey Form</h1>
            <h3 v-if="is_ended == false && is_started == true" class="fs-20 py-2 fw-normal">Survey Brief</h3>
            <div v-if="is_ended == false && is_started == true" class="d-flex w-100">
                <span class="fw-normal fs-13">{{description}}</span>
            </div>
        </div>
        <div v-if="is_ended == true" class="px-5 mt-10 text-center">
            <img src="/static/images/survey-expired.svg" alt="" width="250px">
            <h3 class="fs-14 fw-600 py-4 fw-normal" style="color:#206ad3">Survey form has been expired!</h3>
        </div>
        <div v-if="is_started == false" class="px-5 mt-10 text-center">
            <img src="/static/images/survey-not-started.svg" alt="" width="300px">
            <h3 class="fs-14 fw-600 py-4 fw-normal" style="color:#206ad3">Survey form has been not started!</h3>
        </div>
        <div v-if="is_ended == false && is_started == true">
            <div class="px-5 mt-4 d-flex justify-content-between">
                <div class="field-left">
                    <span class="fs-14 fw-600 px-2" style="color: #0475f3;">
                        Email ID<span class="text-danger fs-14 pl-1">*</span>
                    </span>
                    <div class="d-flex align-items-center">
                        <input name="email" v-validate="'required|email'"
                            class="inputFieldNew mt-3 mr-2" v-model="email" type="text"
                            placeholder="Enter Email ID" />
                    </div>
                    <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('email')">
                        {{errors.first('email')}}
                    </span>
                </div>
                <div class="field-right">
                    <span class="fs-14 fw-600 px-2" style="color: #0475f3;">
                        User Name<span class="text-danger fs-14 pl-1">*</span>
                    </span>
                    <div class="d-flex align-items-center">
                        <input name="user_name" v-validate="'required'"
                            class="inputFieldNew mt-3" v-model="user_name" type="text"
                            placeholder="Enter User Name" />
                    </div>
                    <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('user_name')">
                        {{errors.first('user_name')}}
                    </span>
                </div>
            </div>
            
            
            <div class="px-5 my-5">
                <div class="d-flex justify-content-between">
                    <h3 style="color:#4e85f2" class="fs-17 fw-normal pt-4">Survey Questions</h3>
                    <div class="d-flex justify-content-end align-items-center">
                        <button @click="firstPage()" :disabled="skip == 0" class="pagination-list mt-1 pointer">
                            <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                                <i class="icon fs-20 icon-chevron-double-left color-white"></i>
                            </el-tooltip>
                        </button>
                        <button @click="previousPage()" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                                <i class="icon fs-20 icon-chevron-left color-white"></i>
                            </el-tooltip>
                        </button>
                        <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} -
                            {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                        <button @click="nextPage()" :disabled="skip_temp >= total" class="pagination-list mt-1 mr-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                                <i class="icon fs-20 icon-chevron-right color-white"></i>
                            </el-tooltip>
                        </button>
                        <button @click="lastPage()" :disabled="temp_total == total" class="mt-1 pointer pagination-list">
                            <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                                <i class="icon  fs-20 icon-chevron-double-right color-white"></i>
                            </el-tooltip>
                        </button>
                    </div>
                </div>
                <div v-for="(question, question_index) in questions" :key="question_index+'question_index'">
                    <div v-if="question.question_type == 'Short Answer' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form">
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <input v-if="question.is_mandatory" v-validate="'required'" data-vv-as="Answer" :name="'answer'+question_index" type="text" placeholder="Short Answer" class="input-border-bottom" v-model="question.answer" />
                            <input v-else type="text" placeholder="Short Answer" class="input-border-bottom" v-model="question.answer" />
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('answer'+question_index)">
                                {{errors.first('answer'+question_index)}}
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Paragraph' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form" >
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <input v-if="question.is_mandatory" v-validate="'required'" data-vv-as="Answer" :name="'answer'+question_index" type="text" placeholder="Short Answer" class="input-border-bottom" v-model="question.answer" />
                            <input v-else type="text" placeholder="Long Answer" class="input-border-bottom" v-model="question.answer" />
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('answer'+question_index)">
                                {{errors.first('answer'+question_index)}}
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Multiple Choice' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form" >
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div v-for="(mul_choice_answer, mul_choice_answer_index) in question.answer_list" :key="mul_choice_answer_index+'mul_choice_answer_index'" class="d-flex align-items-end mt-3 multiple-choice">
                                <div class="form-check form-check-inline mx-0">
                                    <label class="form-check-label check-label-dc" :class="{'option-not-selected':errors.has('mul_choice_answer')}">
                                        <input v-if="question.is_mandatory" v-validate="'required'" data-vv-as="Multiple choice" v-model="mul_choice_answer.checked" name="mul_choice_answer_index" @change="multipleChoice($event, question_index, mul_choice_answer_index, mul_choice_answer)" class="form-check-input" type="radio" />
                                        <input v-else v-model="mul_choice_answer.checked" @change="multipleChoice($event, question_index, mul_choice_answer_index, mul_choice_answer.value)" name="mul_choice_answer_index" class="form-check-input" type="radio" />
                                        <span class="radio-icon"></span>
                                    </label>
                                </div>
                                <div style="min-width: 200px !important;" class="input-border-bottom pb-0" >
                                    {{mul_choice_answer.value}}
                                </div>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('mul_choice_answer_index')">
                                {{errors.first('mul_choice_answer_index')}}
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Checkboxes' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form">
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div v-for="(check_answer, check_answer_index) in question.answer_list" :key="check_answer_index+'check_answer_index'" class="d-flex align-items-end mt-3">
                                <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                    <input v-if="question.is_mandatory" v-validate="'required'" @change="checkBoxChange($event, question_index, check_answer_index, check_answer.value)" data-vv-as="Checkboxes" class="custom-control-input" type="checkbox" v-model="check_answer.checked"
                                    name="checkboxes" />
                                    <input v-else class="custom-control-input" type="checkbox" @change="checkBoxChange($event, question_index, check_answer_index, check_answer.value)" v-model="check_answer.checked"
                                    name="checkboxes" />
                                    <span class="custom-control-indicator" style="font-size: 20px; top: -3px !important;"></span>
                                    <span class="form-check-description fw-600"></span>
                                </label>
                                <div style="min-width: 200px !important;" class="input-border-bottom pb-0 ml-1" >
                                    {{check_answer.value}}
                                </div>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('checkboxes')">
                                Please select at least one option
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Dropdown' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form">
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div v-if="question.is_mandatory" class="row mt-3">
                                <div class="col-lg-10 col-md-10 col-sm-12">
                                    <multiselect class="diginew-multiselect selectedData" name="dropdown_type" @select="dropdownSelected($event, question_index)" v-validate="'required'" :show-labels="false" :internal-search="true" :max-height="200" :show-no-results="true" :searchable="true" :options="question.answer_list" v-model="question.answer" open-direction="bottom" placeholder="Select">
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <!-- <template slot="afterList">
                                            <div v-observe-visibility="reachedEndOfListDropdown" />
                                        </template> -->
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <div v-else class="mt-3 row">
                                <div class="col-lg-10 col-md-10 col-sm-12">
                                    <multiselect class="diginew-multiselect selectedData" name="dropdown_type" @select="dropdownSelected($event, question_index)" :show-labels="false" :internal-search="true" :max-height="200" :show-no-results="true" :searchable="true" :options="question.answer_list" v-model="question.answer" placeholder="Select">
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                </div>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('dropdown_type')">
                                Please select an option
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Star Ratings' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form">
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div class="mt-3">
                                <star-rating v-if="question.is_mandatory" v-model="question.answer" v-validate="'required'" :border-width="1" name="star_rating" :show-rating="false" :max-rating="question.number_of_stars" @rating-selected="setCurrentSelectedRating($event, question_index)" :star-size="25" inactive-color="#fff" active-color="#ff9a06" border-color="#ff9a35" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                                <star-rating v-else :border-width="1" v-model="question.answer" name="star_rating" :show-rating="false" :max-rating="question.number_of_stars" @rating-selected="setCurrentSelectedRating($event, question_index)" :star-size="25" inactive-color="#fff" active-color="#ff9a06" border-color="#ff9a35" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('star_rating')">
                                Please select at least one star.
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Multiple Choice Grids' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form">
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div class="d-flex position-relative">
                                <div class="">
                                    <div class="d-flex flex-column mt-4">
                                        <div class="d-flex align-items-center" >
                                            <div class="mt-2"></div>
                                        </div>
                                        <span v-for="(row_title, row_title_index) in question.row_titles" :key="row_title_index+'row_title_index'" class="mt-2 d-flex flex-column">
                                            <span class="mx-1 my-2">{{row_title}}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="ml-1">
                                    <div class="d-flex flex-column">
                                        <span class="d-flex align-items-center" >
                                            <span v-for="(column_title, column_title_index) in question.column_titles" :key="column_title_index+'column_title_index'" style="min-width: 24px;text-align: center;" class="w-100 mx-3">{{column_title}}</span>
                                        </span>
                                        <span v-for="(mult_ans, index) in question.multiple_answers" :key="index+'mult'" class="mt-2 d-flex align-items-center">
                                            <span class="m-2 w-100 d-flex align-items-center justify-content-center text-center" v-for="(inner_ans, inner_index) in question.multiple_answers[index]" :key="inner_index+'inner_index'" >
                                                <div v-if="question.is_mandatory" class="form-check form-check-inline mx-0">
                                                    <label class="form-check-label check-label-dc" :class="{'is-required':errors.has('mul_choice_grid'+index)}">
                                                        <input data-vv-as="Multiple Choice Grid" v-validate="'required'" v-model="question.multiple_answers[index][inner_index]" :id="'mul_choice_grid'+(index+''+inner_index)" @change="mulChoiceGrid($event, question_index, index, inner_index)" :name="'mul_choice_grid'+index" class="form-check-input" type="radio" />
                                                        <span class="radio-icon"></span>
                                                    </label>
                                                </div>
                                                <div v-else class="form-check form-check-inline mx-0">
                                                    <label class="form-check-label check-label-dc">
                                                        <input v-model="question.multiple_answers[index][inner_index]" :id="'mul_choice_grid'+(index+''+inner_index)" @change="mulChoiceGrid($event, question_index, index, inner_index)" :name="'mul_choice_grid'+index" class="form-check-input" type="radio" />
                                                        <span class="radio-icon"></span>
                                                    </label>
                                                </div>
                                            </span>
                                            <span style="bottom: -30px; left: 0;" class="invalid-feedback-form fs-14 text-danger position-absolute" v-show="errors.has('mul_choice_grid'+index)">
                                                {{errors.first('mul_choice_grid'+index)}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Checkboxes Grid' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form" >
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div class="d-flex">
                                <div class="">
                                    <div class="d-flex flex-column mt-2">
                                        <div class="d-flex align-items-center">
                                            <div class="mt-2"></div>
                                        </div>
                                        <span v-for="(row_title, row_title_index) in question.row_titles" :key="row_title_index+'row_title_index'" class="mt-2 d-flex flex-column">
                                            <span class="mx-1 my-2">{{row_title}}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="ml-1">
                                    <div class="d-flex flex-column">
                                        <span class="d-flex align-items-center" >
                                            <span v-for="(column_title, column_title_index) in question.column_titles" :key="column_title_index+'column_title_index'" class="mx-3">{{column_title}}</span>
                                        </span>
                                        <span v-for="(mult_ans, index) in question.multiple_answers" :key="index+'mult'" class="margin-que d-flex align-items-center" style="margin-top: 0.5rem !important;">
                                            <span class="m-2 w-100 d-flex align-items-center justify-content-center text-center" v-for="(inner_ans, inner_index) in question.multiple_answers[index]" :key="inner_index+'inner_index'" >
                                                <label v-if="question.is_mandatory" class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                    <input class="custom-control-input" v-validate="'required'" type="checkbox" @change="checkboxesGrid($event, question_index, index, inner_index)" v-model="question.multiple_answers[index][inner_index]"
                                                    name="checkboxes_gridd" />
                                                    <span class="custom-control-indicator" style="font-size: 20px; "></span>
                                                    <span class="form-check-description fw-600"></span>
                                                </label>
                                                <label v-else class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                    <input class="custom-control-input" type="checkbox" @change="checkboxesGrid($event, question_index, index, inner_index)" v-model="question.multiple_answers[index][inner_index]"
                                                    name="checkboxes_gridd" />
                                                    <span class="custom-control-indicator" style="font-size: 20px; "></span>
                                                    <span class="form-check-description fw-600"></span>
                                                </label>
                                                <!-- <input type="checkbox" v-model="question.multiple_answers[index][inner_index]" /> -->
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('checkboxes_gridd')">
                                Please select at least one option
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Date' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form" >
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div class="d-flex align-items-center mt-3">
                                <date-picker v-if="question.is_mandatory" v-validate="'required'" class="new-date-picker input-field-underline pr-0" placeholder="MM/DD/YYYY"
                                    v-model="question.answer" data-vv-as="Date" value-type="format" :name="'date'+question_index" style="width: 150px !important;" lang="en"
                                    type="date" format="MM/DD/YYYY" @input="dateSelect($event, question_index)" :not-before="new Date()" :clearable="false" :editable="false" confirm confirm-text="APPLY">
                                </date-picker>
                                <date-picker v-else class="new-date-picker input-field-underline pr-0" placeholder="MM/DD/YYYY"
                                    v-model="question.answer" value-type="format" style="width: 150px !important;" lang="en"
                                    type="date" format="MM/DD/YYYY" @input="dateSelect($event, question_index)" :not-before="new Date()" :clearable="false" :editable="false" confirm confirm-text="APPLY">
                                </date-picker>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('date'+question_index)">
                                {{errors.first('date'+question_index)}}
                            </span>
                        </div>
                    </div>
                    <div v-if="question.question_type == 'Time' && (skip === question_index)" class="questionnaire-box mt-4">
                        <div class="question-form">
                            <span class="input-label pl-0">
                                Question {{question.seq_number}}
                                <span v-if="question.is_mandatory" class="text-danger fs-14 pl-1">*</span>
                            </span>
                            <p class="fs-14 color-gray pt-2" style="word-break: break-word;">
                                {{question.question}}
                            </p>
                            <div class="d-flex align-items-center mt-3">
                                <date-picker v-if="question.is_mandatory" v-validate="'required'" class="new-time-picker input-field-underline" v-model="question.answer"
                                    placeholder="--:--" data-vv-as="Time" :name="'time'+question_index" value-type="format"
                                    style="width: 150px !important; padding-right: 2px !important;" lang="en"
                                    type="time" @input="timeSelect($event, question_index)" format="hh:mm a" :clearable="false" :editable="false" confirm confirm-text="APPLY">
                                </date-picker>
                                <date-picker v-else class="new-time-picker input-field-underline" v-model="question.answer"
                                    placeholder="--:--" :name="'answer'+question_index" value-type="format"
                                    style="width: 150px !important; padding-right: 2px !important;" lang="en"
                                    type="time" @input="timeSelect($event, question_index)" format="hh:mm a" :clearable="false" :editable="false" confirm confirm-text="APPLY">
                                </date-picker>
                            </div>
                            <span class="invalid-feedback-form fs-14 text-danger" v-show="errors.has('time'+question_index)">
                                {{errors.first('time'+question_index)}}
                            </span>
                        </div>
                    </div>
                    <div v-if="(skip === question_index)">
                        <div v-if="total == 1" class="d-flex justify-content-center mt-4">
                            <button type="button" style="border-color:#009cd2;color:#009cd2"
                                class="br-0 btn text-uppercase px-4" :disabled="submit_disabled" @click="clearAnswers(question_index)">Clear</button>
                            <button type="button" class="br-0 ml-4 text-uppercase text-white color-blue px-4 btn" :disabled="submit_disabled" @click="submitOneAnswer(question_index)">Submit</button>
                        </div>
                        <div v-else class="d-flex justify-content-center mt-4">
                            <button type="button" style="border-color:#009cd2;color:#009cd2"
                                class="br-0 btn text-uppercase px-4" :disabled="submit_disabled" @click="clearAnswers(question_index)">Clear</button>
                            <button v-if="skip > 0" type="button" :disabled="submit_disabled" @click="previousQuestion()" style="border-color:#009cd2;color:#009cd2"
                                class="br-0 btn text-uppercase ml-4 px-4">Previous</button>
                            <button v-if="submit_btn" type="button" class="br-0 ml-4 text-uppercase text-white color-blue px-4 btn" :disabled="submit_disabled" @click="submitForm()">Submit</button>
                            <button v-else type="button" @click="nextQuestion()" :disabled="submit_disabled" class="br-0 ml-4 text-uppercase text-white color-blue px-4 btn">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <submitted-successfully-modal @hide-submit-successful-modal="hideSubmitSuccessfulModal" @showSharePopup="showSharePopup"
            modal_name="Submitted-Successfully-Modal" :message="submit_success_msg" :is_share="is_share" v-if="submit_success"></submitted-successfully-modal>
        <submission-failed-modal @hide-submit-failed-modal="hideSubmissionFailedModal" 
            modal_name="Submission-Failed-Modal" :error_reason="error_reason" v-if="submit_failed"></submission-failed-modal>
        <send-surveys-info @hide-send-survey-info="hideSendSurveyInfo" :form_id="form_id" modal_name="Send_Surveys_Info" v-if="send_surveys_info"></send-surveys-info>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
    import globals from '../globals';
    const SubmittedSuccessfullyModal = () => import('./SubmittedSuccessfullyModal');
    const SubmissionFailedModal = () => import('./SubmissionFailedModal');
    const SendSurveysInfo = () => import('./SendSurveysInfo');
    import { SweetModal } from 'sweet-modal-vue';
    import survey_forms from '../mixins/survey_forms';
    export default {
        mixins: [survey_forms],
        data() {
            return {
                question_number: 1,
                submit_success: false,
                submit_failed: false,
                description: '',
                warning_msg: '',
                success_msg: '',
                email:'',
                user_name: '',
                questions: [],
                limit: 1,
                skip: 0,
                count: 0,
                start: 0,
                end: 0,
                total: 0,
                skip_temp: 0,
                temp_total: 0,
                answers_selected: [],
                error_reason: '',
                submit_btn: false,
                submit_success_msg: '',
                is_share: false,
                form_id: '',
                send_surveys_info: false,
                submit_disabled: false,
                is_ended: false,
                is_started: false,
            }
        },
        components: {
            SubmittedSuccessfullyModal,
            SubmissionFailedModal,
            SendSurveysInfo,
            SweetModal
        },
        methods: {
            dateSelect(event, index) {
                this.questions[index].answer = event;
                this.$forceUpdate();
            },
            timeSelect(event, index) {
                this.questions[index].answer = event;
                this.$forceUpdate();
            },
            dropdownSelected(event, index) {
                this.questions[index].answer = event;
                this.$forceUpdate();
            },
            showSharePopup() {
                this.submit_success = false;
                setTimeout(() => {
                    this.$modal.hide('Submitted-Successfully-Modal')
                }, 500);
                this.send_surveys_info = true
                setTimeout(() =>{
                    this.$modal.show("Send_Surveys_Info")
                },500)
            },
            hideSendSurveyInfo(){
                this.send_surveys_info = false;
                setTimeout(() =>{
                    this.$modal.hide("Send_Surveys_Info")
                },500)
            },
            setCurrentSelectedRating(rating, index) {
                this.questions[index].answer = rating;
                this.$forceUpdate();
            },
            OpenSubmitSuccessModal() {
                this.submit_success = true
                setTimeout(() => {
                    this.$modal.show('Submitted-Successfully-Modal')
                }, 500);
            },
            hideSubmitSuccessfulModal() {
                this.submit_success = false;
                window.location.reload();
            },
            OpenSubmitFailedModal(){
                this.submit_failed = true
                setTimeout(() => {
                    this.$modal.show('Submission-Failed-Modal')
                })
            },
            hideSubmissionFailedModal(){
                this.submit_failed = false;
                window.location.reload();
            },
            previousPage() {
                this.submit_btn = false;
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            },
            nextPage() {
                this.$validator.validateAll().then(result => {
                    if (result == true) {
                        this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                        if (this.skip_temp >= this.total) {
                            return;
                        }
                        this.skip = this.skip_temp;
                    }
                });
            },
            firstPage() {
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
            },
            lastPage() {
                this.$validator.validateAll().then(result => {
                    if (result == true) {
                        this.skip_temp = 0
                        this.skip = 0
                        this.temp_total = this.total
                        let no_of_pages = Math.ceil(this.total / this.limit)
                        this.skip_temp = (no_of_pages * this.limit) - this.limit
                        if (this.skip_temp >= this.total) {
                            return;
                        }
                        this.skip = this.skip_temp
                    }
                });
            },
            previousQuestion() {
                this.submit_btn = false;
                this.previousPage();
            },
            nextQuestion() {
                this.$validator.validateAll().then(result => {
                    if (result == true) {
                        if(this.skip+1 == this.total){
                            this.submit_btn = true;
                        }
                        this.nextPage();
                    }
                });
            },
            submitOneAnswer(index) {
                this.$validator.validateAll().then(result => {
                    if (result == true) {
                        this.submitForm()
                    }
                });
            },
            async getQuestion(id) {
                let params = {
                    form_id: id,
                    // skip: this.skip,
                    // limit: this.limit,
                    // question: 'individual'
                }
                try {
                    let response = await this.getSurveyFormById(params);
                    this.description = response.response.form.description;
                    this.questions = response.response.form.questions;
                    this.total = response.response.form.questions.length;
                    this.is_ended = response.response.form.is_ended;
                    this.is_started = response.response.form.is_started;
                    this.questions.forEach((que, index) => {			
                        if(que.question_type == 'Short Answer') {
                            que.answer = ''
                            que.answer_list = []
                            que.multiple_answers = []
                        } 
                        if(que.question_type == 'Paragraph') {
                            que.answer = ''
                            que.answer_list = []
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Time') {
                            que.answer = ''
                            que.answer_list = []
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Date') {
                            que.answer = ''
                            que.answer_list = []
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Star Ratings') {
                            que.answer = ''
                            que.answer_list = []
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Multiple Choice') {
                            que.answer = ''
                            que.answer_list = []
                            que.options.forEach((option, index) => {
                                que.answer_list.push({
                                    value: option,
                                    checked: false
                                })
                            })
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Dropdown') {
                            que.answer = ''
                            que.answer_list = que.options
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Checkboxes') {
                            que.answer = ''
                            que.answer_list = []
                            que.options.forEach((option, index) => {
                                que.answer_list.push({
                                    value: option,
                                    checked: false
                                })
                            })
                            que.multiple_answers = []
                        }
                        if(que.question_type == 'Multiple Choice Grids') {
                            que.multiple_answers = new Array(que.row_titles.length);
                            for (var i = 0; i < que.multiple_answers.length; i++) {
                                que.multiple_answers[i] = new Array(que.column_titles.length);
                            }
                            var h = 0;
                            for (var i = 0; i < que.row_titles.length; i++) {
                                for (var j = 0; j < que.column_titles.length; j++) {
                                    que.multiple_answers[i][j] = h;
                                }
                            }
                            que.answer = ''
                            que.answer_list = []
                        }
                        if(que.question_type == 'Checkboxes Grid') {
                            que.multiple_answers = new Array(que.row_titles.length);
                            for (var i = 0; i < que.multiple_answers.length; i++) {
                                que.multiple_answers[i] = new Array(que.column_titles.length);
                            }
                            var h = 0;
                            for (var i = 0; i < que.row_titles.length; i++) {
                                for (var j = 0; j < que.column_titles.length; j++) {
                                    que.multiple_answers[i][j] = h;
                                }
                            }
                            que.answer = ''
                            que.answer_list = []
                        }
                        localStorage.setItem('questions', JSON.stringify(this.questions));
                    });
                }
                catch(err) {
                    this.warning_msg = err
                    this.$refs.warning_modal.open();
                    // setTimeout(() => {
                    //     this.$refs.warning_modal.close();
                    // }, 3000);
                }
            },
            clearAnswers(index) {
                let default_ans = JSON.parse(localStorage.getItem('questions'))
                this.questions[index].answer = default_ans[index].answer
                this.questions[index].answer_list = default_ans[index].answer_list
                for (var i = 0; i < this.questions[index].multiple_answers.length; i++) {
                    for (var j = 0; j < this.questions[index].multiple_answers[i].length; j++) {
                        this.questions[index].multiple_answers[i][j] = 0;
                    }
                }
                // this.questions[index].number_of_stars = 0
                this.$forceUpdate();
            },
            multipleChoice(event, que_index, ans_index, value) {
                this.questions[que_index].answer = value.value  ? value.value : value || '';
                for (var i = 0; i < this.questions[que_index].answer_list.length; i++) {
                    if(i == ans_index) {
                        this.questions[que_index].answer_list[i].checked = null
                    } else {
                        this.questions[que_index].answer_list[i].checked = false
                    }
                }
                this.$forceUpdate();
            },
            checkBoxChange(event, que_index, ans_index, value) {
                this.questions[que_index].answer_list[ans_index].value = value;
                if(event.target.checked) {
                    this.questions[que_index].answer_list[ans_index].checked = true;
                } else {
                    this.questions[que_index].answer_list[ans_index].checked = false;
                }
                this.$forceUpdate();
            },
            mulChoiceGrid(event, que_index, ans_index_row, ans_index_column) {
                this.questions[que_index].multiple_answers[ans_index_row][ans_index_column] = null;
                for (var i = 0; i < this.questions[que_index].multiple_answers.length; i++) {
                    for (var j = 0; j < this.questions[que_index].multiple_answers[i].length; j++) {
                        if(ans_index_row == i && ans_index_column != j) {
                            this.questions[que_index].multiple_answers[i][j] = 0;
                        }
                    }
                }
                this.$forceUpdate();
            },
            checkboxesGrid(event, que_index, ans_index_row, ans_index_column) {
                if(event.target.checked) {
                    this.questions[que_index].multiple_answers[ans_index_row][ans_index_column] = true
                } else {
                    this.questions[que_index].multiple_answers[ans_index_row][ans_index_column] = 0
                }
                this.$forceUpdate();
            },
            submitForm() {
                this.create_data.user_answers.forEach((ans, index) => {
                    ans.question_id = ans.id;
                    if(ans.question_type == 'Date') {
                        ans.answer = ans.answer == '' ? '' : ans.answer.split('/')[2]+'-'+ans.answer.split('/')[0]+'-'+ans.answer.split('/')[1];
                    }
                    if(ans.question_type == 'Multiple Choice') {
                        ans.answer_list = [];
                        ans.answer = ans.answer || '';
                    }
                    if(ans.question_type == 'Dropdown') {
                        ans.answer_list = [];
                    }
                    if(ans.question_type == 'Checkboxes') {
                        ans.answer_list.forEach((element, index) => {
                            if(ans.answer_list[index].checked == true) {
                                ans.answer_list[index] = element.value
                            }
                            else {
                                ans.answer_list[index] = ''
                            }
                        });
                        ans.answer_list = ans.answer_list.filter(el => el);
                    }
                    if(ans.question_type == 'Multiple Choice Grids') {
                        for (var i = 0; i < ans.multiple_answers.length; i++) {
                            for (var j = 0; j < ans.multiple_answers[i].length; j++) {
                                if(ans.multiple_answers[i][j] == null) {
                                    ans.multiple_answers[i][j] = 1;
                                } else {
                                    ans.multiple_answers[i][j] = 0;
                                }
                            }
                        }
                    }
                    if(ans.question_type == 'Checkboxes Grid') {
                        for (var i = 0; i < ans.multiple_answers.length; i++) {
                            for (var j = 0; j < ans.multiple_answers[i].length; j++) {
                                if(ans.multiple_answers[i][j] == true) {
                                    ans.multiple_answers[i][j] = 1;
                                } else {
                                    ans.multiple_answers[i][j] = 0;
                                }
                            }
                        }
                    }
                    delete ans.column_titles
                    delete ans.created_date_time
                    delete ans.form_id
                    delete ans.id
                    delete ans.is_mandatory
                    delete ans.last_modified_date_time
                    delete ans.number_of_stars
                    delete ans.options
                    delete ans.question
                    delete ans.question_type
                    delete ans.question_type_id
                    delete ans.row_titles
                    delete ans.seq_number
                })
                this.$validator.validateAll().then(result => {
                    if (result == true) {
                        this.submitSuccess();
                    } else {
                        
                    }
                });
            },
            async submitSuccess() {
                this.submit_disabled = true;
                try {
                    await this.$http.post(globals.CRM_SALES_URL + `/survey_forms/answers`, this.create_data).then((response) => {
                        if(response.data.status_id == 1) {
                            this.submit_success = true
                            this.form_id = response.data.form_id;
                            this.is_share = response.data.share_link;
                            this.submit_success_msg = response.data.response_to_the_user
                            setTimeout(() => {
                                this.$modal.show('Submitted-Successfully-Modal')
                            }, 500);
                        } else {
                            this.error_reason = response.data.reason
                            this.submit_failed = true
                            setTimeout(() => {
                                this.$modal.show('Submission-Failed-Modal')
                            }, 500)
                        }
                    }).catch((err) => {
                        this.error_reason = 'We are not accepting further response for this survey form.'
                        this.submit_failed = true
                        setTimeout(() => {
                            this.$modal.show('Submission-Failed-Modal')
                        }, 500)
                        // this.submit_disabled = false;
                    });
                }
                catch(err) {
                    this.warning_msg = err
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                    // this.submit_disabled = false;
                }
            },
        },
        mounted() {
            if(this.$route.query.survey_id) {
                localStorage.setItem('survey_id', this.$route.query.survey_id);
                this.getQuestion(this.$route.query.survey_id);
            } else {
                this.getQuestion(localStorage.getItem('survey_id'));
            }
        },
        computed: {
            getSkipCount() {
                if (this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip > this.total ? this.total : this.skip + 1;
                    return skip_count;
                }
            },
            create_data() {
                return {
                    form: this.$route.query.survey_id ? this.$route.query.survey_id : localStorage.getItem('survey_id'),
                    email: this.email,
                    username: this.user_name,
                    user_answers: this.questions
                }
            }
        },
        created() {
            const dict = {
                custom: {
                    email: {
                        required: 'Email is required',
                        email: 'Email valid email'
                    },
                    user_name:{
                        required:() => "User name is required",
                    },
                }
            }
            this.$validator.localize('en', dict)
        }
    }
</script>

<style scoped>
    .br-0 {
        border-radius: 0px;
    }

    .color-blue {
        background: #009cd2;
    }

    .pagination-list {
        background: #009cd2;
        border-radius: 4px;
        padding: 1px 5px 1px 5px;
        border: 0px;
    }

    .icon-orange {
        color: #ff9a06;
    }

    .color-white {
        color: #fff;
    }

    .questionnaire-box {
        padding: 50.1px 44.2px 60.8px 30px;
        border-radius: 3px;
        border: solid 0.5px rgba(108, 109, 111, 0.3);
        background-color: #fff;
    }

    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 6px 10px;
        font-size: 14px;
    }

    .color-gray {
        color: #3f3f3f;
    }

    .input-border-bottom {
        border: 0 !important;
        padding: 5px 8px;
        padding-left: 0;
        width: 100% !important;
        outline:none;
        border-bottom: solid 1px #707070 !important;
    }

    .input-field-underline {
        border: 0 !important;
        border-bottom: solid 0.5px #3f3f3f !important;
        background-color: #fff;
        padding: 0 !important;
        width: 100% !important;
        /* height: 40px; */
    }

    .inputFieldNew-border-bottom {
        font-size: 14px !important;
        padding: 10px 11px 7px 8px !important;
        display: block;
        width: 100%;
        border: none;
        border-bottom: solid 1px #707070 !important;
        background: transparent !important;
        color: #303031;
        font-weight: 600;
        /* margin-top: 20px; */
    }

    .field-left, .field-right{
        width:30%;
    }

    @media screen and (max-width:992px) {
        .field-left, .field-right{
            width:50%;
        }
    }

    .input-field-underline {
        border: 0 !important;
        border-bottom: solid 0.5px #3f3f3f !important;
        background-color: #fff;
        padding: 0 !important;
        width: 100% !important;
    }
   
</style>